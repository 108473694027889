import { Context } from '@nuxt/types'
import '@nuxtjs/axios'

export default function ({ $axios, redirect, i18n, error }: Context) {
  $axios.setHeader('Language', i18n.locale)
  $axios.defaults.maxRedirects = 0;
  $axios.onError((axiosError) => {
    if (!axiosError.isAxiosError) {
      // console.error('axiosError.isAxiosError', axiosError);
    }
    if (axiosError.response?.status === 500) {
      // redirect('/error')
      // console.error(axiosError.response)
      error({ statusCode: 404 })
    }
    if (axiosError.response?.status === 404) {
      // console.log('axiosError.response?.status', axiosError.response?.status)
      error({ statusCode: 404, path: axiosError.stack, message: 'Not found' })
    }
    if (axiosError.response?.status === 302 || axiosError.response?.status === 301) {
      if (axiosError.response?.headers.location)
      return redirect(axiosError.response?.status, axiosError.response?.headers.location)
    }
    throw new Error(axiosError.message);
  })
}
