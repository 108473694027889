
import { Component, Vue } from 'nuxt-property-decorator'
import PhoneList from './PhoneList.vue'
import LangSelector from './LangSelector.vue'
import Logo from './Logo.vue'
import HeaderAuth from './HeaderAuth.vue'
import Cart from '~/modules/cart/components/CartHeader.vue'
import Search from '~/modules/search/components/Search.vue'
@Component(
  {components: { PhoneList, LangSelector, Logo, Search, Cart, HeaderAuth }}
)
export default class extends Vue {}
