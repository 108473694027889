import { GetterTree } from 'vuex';
import { StateInterface } from '../index';
import { ICartStateInterface } from './state';

const getters: GetterTree<ICartStateInterface, StateInterface> = {
  countProducts (context) {
    return context.products.length
  },

  totalProducts (context) {
    return context.products.reduce((s, e) => s + +e.count, 0) || 0
  }
};

export default getters;
