import { Context } from "@nuxt/types";

export default async function ({ route, redirect, $axios, $config, error }: Context, next: () => {}) {
  if (route.query.lang !== undefined) {
    let _lang = '';
    switch(route.query.lang) {
      case 'ru':
        _lang = 'ru';
      break;
    }
    let url = route.path;
    if (_lang && !route.path.startsWith(`/${_lang}`)) {
      url = `/${_lang}${route.path}`
    }
    if (_lang.length === 0 && route.path.startsWith(`/ru`)) {
      url = route.path.replace('/ru', '');
    }
    return redirect(301, url);
  }
  if (route.path.includes('/categories/')) {
    const cat = route.params.cat;
    if (cat === undefined || cat.toString().trim() === '') {
      error({ statusCode: 404, message: `Category params not found: ${cat}` })
      console.error(`1. Category params not supported: ${cat}`)
      return next();
    }
    const _filter = cat.split('_')
    if (![1, 5, 7, 9].includes(_filter.length)) {
      error({ statusCode: 404, message: `Category params not supported: ${cat}` })
      console.error(`2. Category params not supported: ${cat}`)
      return next();
    }
    if (_filter.length > 1 && !['cars', 'trucks'].includes(_filter[0])) {
      error({ statusCode: 404, message: `Category params not supported: ${cat}` })
      console.error(`3. Category params not supported: ${cat}`)
      return next();
    }
  }
  if (!process.server) return next()
  if (!route.path.includes('/product/') && !route.path.includes('/catalog/') && !route.path.includes('/categories/') && !route.path.includes('/page/')) return next()
  try {
    const response = await $axios.post(`/control/check-url?key=${$config.universalApiKey}`, { path: route.path })
    if (response.data && response.data.to && response.data.code > 0) {
      return redirect(response.data.code, response.data.to)
    }
    if (route.path.includes('/catalog/') && route.path.includes('/fuel-')) {
      const newUrl = route.path.replace(/(\/fuel-[a-z]+)/, '');
    return redirect(301, newUrl);
    }
  } catch (e) {
    console.error(e)
  }
  next()
}
