import {CarSelectorStore} from "~/components/car-selector/store";

export default class Category {
  img: string
  title: string;
  description: string;
  route: string;
  routeParams!: Record<string, any>;
  isAvailability = true;

  constructor(img: any, title: string, description: string, route: string, routeParams = {}) {
    this.img = img
    this.title = title
    this.description = description
    // this.title = $t(`component.categories.${key}.title`)||title
    // this.description = $t(`component.categories.${key}.description`)||description
    this.route = route
    this.routeParams = routeParams
  }

  getLink(params: Record<string, any> = {}, carSelected: any = null, vue: any = null) {
    const carsCode = ['trucks','cars']
    const routeParams = Object.assign({}, this.routeParams);

    if (!carSelected) carSelected = CarSelectorStore.getCarSelected()
    if (carSelected) {
      const _p = [];
      let typeBrandId = 0
      for (const f of carSelected.data) {
        if (!(f.data.value && Object.keys(f.data.base || {}).length)) continue
        let typeCar: number = 0;
        if (f.data.base?.type_car_code) typeCar = parseInt(f.data.base?.type_car_code)
        else if (f.data.base?.type_car_id) {
          typeCar = parseInt(f.data.base?.type_car_id) - 1
        }
        switch (f.key) {
          case 'brand':
            _p.push(carsCode[typeCar])
            _p.push(f.data.base.manufacturer_name_translit)
            typeBrandId = f.data.base.type_brand_id
            // if (typeBrandId == 1)
            _p.push(f.data.base.id)
            // else _p.push(f.data.base.code_manufacturer_1c)
            break
          case 'model':
            _p.push(f.data.base.model_name_translit)
            _p.push(f.data.base.code_model_1c)
            break
          case 'type':
            _p.push(f.data.base.slug)
            _p.push(f.data.base.type_id)
            break
        }
      }
      if (_p.length === 0) {
        _p.push(routeParams.cat)
      } else {
        _p.push(routeParams.id)
        _p.push(typeBrandId)
      }
      params.cat = _p.join('_')
    }
    if (this.route.includes('catalog')) delete params.cat;
    delete routeParams.id;
    return vue ? vue.localePath({name: this.route, params: Object.assign({}, routeParams, params)}): { name: this.route, params: routeParams }
  }

  toJSON () {
    return { ...this } // here I make a POJO's copy of the class instance
  }
}

export const categories = {
  isCheck: false,
  list: [
    new Category(
      '/images/categories/kuzov.webp',
      'component.categories.1.title',
      'component.categories.1.description',
      'catalog.optics_and_part_body',
      {line: 'line-kuzovnye-detali'}
    ),
    new Category(
      '/images/categories/optika.webp',
      'component.categories.2.title',
      'component.categories.2.description',
      'catalog.optics_and_part_body',
      {line: 'line-optika'}
    ),
    new Category(
      '/images/categories/motor.webp',
      'component.categories.3.title',
      'component.categories.3.description',
      'categories.main',
      {cat: 'agregaty-v-sbore', id: 30}
    ),
    new Category(
      '/images/categories/disk.webp',
      'component.categories.4.title',
      'component.categories.4.description',
      'categories.main',
      {cat: 'tormoznye-diski', id: 8}
    ),
    new Category(
      '/images/categories/lamp-2.webp',
      'component.categories.5.title',
      'component.categories.5.description',
      'categories.main',
      {cat: 'avtolampy', id: 26}
    ),
    new Category(
      '/images/categories/amor.webp',
      'component.categories.6.title',
      'component.categories.6.description',
      'categories.main',
      {cat: 'amortizatory', id: 4}
    ),
    new Category(
      '/images/categories/steklo.webp',
      'component.categories.7.title',
      'component.categories.7.description',
      'catalog.optics_and_part_body',
      {line: 'line-avtosteklo'}
    ),
    new Category(
      '/images/categories/glush.webp',
      'component.categories.8.title',
      'component.categories.8.description',
      'categories.main',
      {cat: 'glushiteli', id: 29}
    ),
    new Category(
      '/images/categories/maslo.webp',
      'component.categories.31.title',
      'component.categories.31.description',
      'categories.main',
      {cat: 'filtry', id: 3}
    ),
    new Category(
      '/images/categories/porshen.webp',
      'component.categories.10.title',
      'component.categories.10.description',
      'categories.main',
      {cat: 'zapchasti-na-dvigatel', id: 32}
    ),
    new Category(
      '/images/categories/instruments.webp',
      'component.categories.11.title',
      'component.categories.11.description',
      'categories.main',
      {cat: 'instrument', id: 38}
    ),
    new Category(
      '/images/categories/podshipnik.webp',
      'component.categories.12.title',
      'component.categories.12.description',
      'categories.main',
      {cat: 'podshipniki', id: 15}
    ),
    new Category(
      '/images/categories/cat_31.webp',
      'component.categories.13.title',
      'component.categories.13.description',
      'categories.main',
      {cat: 'valy-kardannye', id: 31}
    ),
    new Category(
      '/images/categories/cat_24.webp',
      'component.categories.14.title',
      'component.categories.14.description',
      'categories.main',
      {cat: 'vodyanye-nasosy', id: 24}
    ),
    new Category(
      '/images/categories/cat_37.webp',
      'component.categories.15.title',
      'component.categories.15.description',
      'categories.main',
      {cat: 'generatory', id: 37}
    ),
    new Category(
      '/images/categories/cat_13.webp',
      'component.categories.16.title',
      'component.categories.16.description',
      'categories.main',
      {cat: 'detali-podveski', id: 13}
    ),
    new Category(
      '/images/categories/cat_33.webp',
      'component.categories.17.title',
      'component.categories.17.description',
      'categories.main',
      {cat: 'kpp-korobka-peredach', id: 33}
    ),
    new Category(
      '/images/categories/cat_34.webp',
      'component.categories.18.title',
      'component.categories.18.description',
      'categories.main',
      {cat: 'mosty', id: 34}
    ),
    new Category(
      '/images/categories/cat_7.webp',
      'component.categories.19.title',
      'component.categories.19.description',
      'categories.main',
      {cat: 'prujiny', id: 7}
    ),
    new Category(
      '/images/categories/cat_23.webp',
      'component.categories.20.title',
      'component.categories.20.description',
      'categories.main',
      {cat: 'radiatory', id: 23}
    ),
    new Category(
      '/images/categories/cat_21.webp',
      'component.categories.21.title',
      'component.categories.21.description',
      'categories.main',
      {cat: 'remni', id: 21}
    ),
    new Category(
      '/images/categories/cat_11.webp',
      'component.categories.22.title',
      'component.categories.22.description',
      'categories.main',
      {cat: 'rulevye-tyagi', id: 11}
    ),
    new Category(
      '/images/categories/cat_22.webp',
      'component.categories.23.title',
      'component.categories.23.description',
      'categories.main',
      {cat: 'svechi-zajiganiya', id: 22}
    ),
    new Category(
      '/images/categories/cat_36.webp',
      'component.categories.24.title',
      'component.categories.24.description',
      'categories.main',
      {cat: 'startera', id: 36}
    ),
    new Category(
      '/images/categories/cat_6.webp',
      'component.categories.25.title',
      'component.categories.25.description',
      'categories.main',
      {cat: 'sceplenie', id: 6}
    ),
    new Category(
      '/images/categories/cat_25.webp',
      'component.categories.26.title',
      'component.categories.26.description',
      'categories.main',
      {cat: 'toplivnye-nasosy', id: 25}
    ),
    new Category(
      '/images/categories/cat_14.webp',
      'component.categories.27.title',
      'component.categories.27.description',
      'categories.main',
      {cat: 'tormoznaya-apparatura', id: 14}
    ),
    new Category(
      '/images/categories/cat_9.webp',
      'component.categories.28.title',
      'component.categories.28.description',
      'categories.main',
      {cat: 'tormoznye-barabany', id: 9}
    ),
    new Category(
      '/images/categories/cat_5.webp',
      'component.categories.29.title',
      'component.categories.29.description',
      'categories.main',
      {cat: 'tormoznye-kolodki', id: 5}
    ),
    new Category(
      '/images/categories/cat_27.webp',
      'component.categories.30.title',
      'component.categories.30.description',
      'categories.main',
      {cat: 'upravlenie-rulevoe', id: 27}
    ),
    new Category(
      '/images/categories/cat_12.webp',
      'component.categories.32.title',
      'component.categories.32.description',
      'categories.main',
      {cat: 'shrusy', id: 12}
    ),
    new Category(
      '/images/categories/cat_10.webp',
      'component.categories.33.title',
      'component.categories.33.description',
      'categories.main',
      {cat: 'shtangi-reaktivnye', id: 10}
    ),
    new Category(
      '/images/categories/cat_28.webp',
      'component.categories.34.title',
      'component.categories.34.description',
      'categories.main',
      {cat: 'schetki-stekloochistitelya', id: 28}
    ),
  ]
};
export const checkAvailabilityCategories = async ($axios: any, forceCheck = false) => {
  if (!forceCheck && categories.isCheck) {
    categories.list.forEach((e) => {
      e.isAvailability = true
    })
    return
  }
  categories.isCheck = true
  const carSelected = CarSelectorStore.getCarSelected()
  if (!carSelected) return false;
  const data: Record<string, any> = {}

  for (const f of carSelected.data) {
    const typeCar: number = parseInt(f.data.base?.type_car_code)
    if (!(f.data.value && Object.keys(f.data.base || {}).length)) continue
    switch (f.key) {
      case 'brand':
        data.type_car = typeCar
        data.manufacturer = f.data.base.id
        break
      case 'model':
        data.model = f.data.base.code_model_1c
        break
      case 'type':
        data.modification = f.data.base.type_id
        break
    }
  }
  const result = await $axios.post('categories/availability', data)
  for (const category of categories.list) {
    if (!category.routeParams.cat) continue;
    if (!result.data.includes(category.routeParams.cat)) category.isAvailability = false
    else category.isAvailability = true
  }
}
