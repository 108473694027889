import { GetterTree } from 'vuex';
import { StateInterface } from '../index';
import { IPagination } from './state';

const getters: GetterTree<IPagination, StateInterface> = {
  totalItems(state: IPagination) {
    if (state.total >= 0) return state.total
    let itemsLength = state.items.length
    if (state.items.length > state.limit) {
      itemsLength = state.items.length % state.limit
    }
    if (state.total < 0) itemsLength++
    // if (state.items.length % state.limit > 0) return state.page * state.items.length
    return (state.page -1 ) * state.limit + itemsLength
  }
};

export default getters;
