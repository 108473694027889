import { Store } from 'vuex';
import userModule from './user';
import seoModule from './seo';
import cartModule from './cart';
import productModule from './product';
import paginationModule from './pagination';
import { IUserStateInterface } from './user/state';
import { ISeo } from './seo/state';
import { ICartStateInterface } from './cart/state';
import { IPagination } from './pagination/state';
import { IProduct } from '~/std/Product';


export interface StateInterface {
  // Define your own store structure, using submodules if needed
  // example: ExampleStateInterface;
  // Declared as unknown to avoid linting issue. Best to strongly type as per the line above.
  user: IUserStateInterface;
  seo: ISeo;
  cart: ICartStateInterface;
  product: IProduct;
  pagination: IPagination;
}

const createStore = () => {
  return new Store({
    strict: false,
    modules: {
      user: userModule,
      seo: seoModule,
      cart: cartModule,
      product: productModule,
      pagination: paginationModule
    }
  });
};

export default createStore