export interface IProduct {
  id: number;
  code: string;
  img: any;
  brand: string;
  name: string;
  link: string;
  description: string;
  article: string;
  price: number;
  availability: number;
  images: string[];
  props: Record<string, any>[];
  inCart: boolean;
  type_link: string;
  brand_link: string;
  type_name: string;
}

export default class Product implements IProduct {
  id: number;
  code: string;
  img!: any
  brand!: string
  name!: string
  link!: string
  description!: string
  article!: string
  price!: number
  availability!: number
  images!: string[]
  type_link!: string
  brand_link!: string
  type_name!: string
  props = []
  inCart = false;

  constructor(product: Record<string, any>) {
    this.id = product.id
    // this.img = require('~/images/icons/delivery.svg')
    this.brand = product.brand_name
    this.name = product.product_name
    this.link = product.link
    this.description = product.description
    this.article = product.article
    this.code = product.product_code
    this.price = product.price
    this.availability = product.availability
    this.images = product.images
  }

  static emptyObj() {
    return new Product({
      id: 0,
      brand_name: '',
      name: '',
      link: '',
      description: '',
      article: '',
      code: '',
      price: 0,
      availability: 0,
      images: [],
    });
  }

  toJSON () {
    return { ...this } // here I make a POJO's copy of the class instance
  }
}
