
import {Component, Vue} from 'nuxt-property-decorator'
import CategoryModel from '../models/Category';
import {categories, checkAvailabilityCategories} from '~/std/Category'
import svgMenu from '~/components/svg/menu.vue'

@Component({components: {svgMenu}})
export default class extends Vue {
  model = {
    category: null as CategoryModel | null
  }

  get categories() {
    return categories.list
  }

  getLink(value: any) {
    return value.getLink({}, null, this)
  }

  // categories = [
  //   new CategoryModel(1, 'Category 1'),
  //   new CategoryModel(2, 'Category 2'),
  // ]

  get categorySelected() {
    return this.model.category ? this.$t(this.model.category.title) : this.$t('component.menuSelector.select')
  }

  mounted() {
    this.$root.$on('onChangeCarSelected', this.updateCategoryAwailability)
    this.$root.$on('resetSelectCategory', this.onResetSelectCategory)
    this.updateCategoryAwailability()
  }

  beforeDestroy() {
    this.$root.$off('onChangeCarSelected', this.updateCategoryAwailability)
    this.$root.$on('resetSelectCategory', this.onResetSelectCategory)
  }

  onResetSelectCategory() {
    if (this.model)
      this.$set(this.model, 'category', null)
  }

  updateCategoryAwailability(force = false) {
    if (!this.$isServer) {
      checkAvailabilityCategories(this.$axios, force).then(() => {
        this.$root.$emit('changeCategoryAvailability')
        this.$nextTick(() => {
          this.$forceUpdate()
        })
      }).catch((e) => {
        console.error(e)
      })
    }
  }
}
