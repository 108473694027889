import Vue from 'vue'
import { Context } from '@nuxt/types'

declare module 'vue/types/vue' {
  interface Vue {
    $localizeRoute: any
  }
}

export default function ({ i18n }: Context) {
  Vue.prototype.$localizeRoute = (routeName: string, lang = undefined) => {
    let currentLang = i18n.locale
    if (!routeName) return routeName;
    if (lang) {
      currentLang = lang
      routeName = routeName.replace(/___\w+$/, '')
    }
    return `${routeName}___${currentLang}`
  }
}