import { ActionTree } from 'vuex';
import { StateInterface } from '../index';
import { ICartStateInterface } from './state';
import Product from '~/std/Product';

const actions: ActionTree<ICartStateInterface, StateInterface> = {
  async refresh ({ commit, state, dispatch }, v: any) {
    if (typeof v?.product === "boolean" && v?.product) {
      return commit('clear')
    }
    if (!v || !v.product) {
      await dispatch('loadProducts');
      await dispatch('getAmount', v && v.params ? v.params : {})
      return
    }
    const products = state.products
    const product = products.find((e: { id: string; price: number }) => e.id === v.product.id)
    if (product && v.product.count > 0) {
      product.price = v.product.price
      product.count = v.product.count
    } else if (v.product) {
      products.push(v.product)
    }
    commit('set', { products })
    dispatch('getAmount', v && v.params ? v.params : {})
  },

  remove({ commit, state, dispatch }, data: any) {
    const products = state.products
    const index = products.findIndex((e: { id: string; price: number }) => e.id === data.product.id && e.price === data.product.price)
    if (index + 1) products.splice(index, 1)
    commit('set', {products})
    dispatch('getAmount', data.params)
  },

  async getAmount({ commit, state }, params: Record<string, any> = {}) {
    if (state.products.length === 0) {
      commit('set', { amount: 0 })
      return;
    }
    const products = state.products.map((pr) => ({ code: pr.code, quantity: pr.count }))
    // @ts-ignore
    await this.dispatch('user/initRecaptcha')
    // @ts-ignore
    const captcha = await this.$recaptcha.execute('order/calculate')
    const data = Object.assign({ verify: captcha, products, type_of_payment: 0 }, params);
    const response = await this.$axios.post('order/calculate', data)
    const amount = parseFloat(response.data.totalPrice + response.data.nds)
    const newProducts: any[] = []
    for (const p of state.products) {
      const _product = response.data.products.find(({ code }: any) => `${p.code}` === `${code}`)
      if (!_product) {
        continue
      } else if (_product.price !== p.price) {
        p.price = _product.price
      }
      newProducts.push(p)
    }
    commit('set', { products: newProducts, amount })
  },

  loadProducts({ commit }) {
    const {products = []} = JSON.parse(localStorage.getItem('cart') || '[]')
    commit('set', { isLoadedProduct: true, products: products.filter((p: any) => p) })
  },

  inCart({ state }, product: Product) {
    return !!state.products.find((v: { code: string }) => `${v.code}` === `${product.code}`)
  }
}
export default actions;
