
import { Component, Vue } from 'nuxt-property-decorator'
import Badge from '~/components/Badge.vue'
import CatgorySelector from '~/modules/category/components/MenuSelector.vue'
@Component({
  components: { Badge, CatgorySelector }
})
export default class extends Vue {
 items:any[] =[
    { name:'menu1', to:'about'},
    { name:'menu2', to:'payment'},
    { name:'menu3', to:'delivery'},
    { name:'menu4', to:'blog'},
    { name:'menu5', to:'reviews'},
    { name:'menu6', to:'contacts'},
  ]

}
