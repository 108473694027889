import { MutationTree } from 'vuex';
import { ICartStateInterface } from './state';

const mutation: MutationTree<ICartStateInterface> = {
  clear (state: ICartStateInterface) {
    state.amount = 0
    state.products = []
    state.isLoadedProduct = false
    localStorage.setItem('cart', JSON.stringify({ products: [] }))
  },

  set(state: ICartStateInterface, cart: Partial<ICartStateInterface>) {
    if (cart.amount) state.amount = cart.amount
    if (cart.products) {
      state.products = cart.products
      localStorage.setItem('cart', JSON.stringify({ products: state.products }))
    }
    if (cart.isLoadedProduct) state.isLoadedProduct = cart.isLoadedProduct
  }
};

export default mutation;
