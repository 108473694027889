export class CarSelectorStore {
  static refresh(car: Record<string, any>) {
    if (!process.browser) return {}
    localStorage.setItem('car', JSON.stringify(car))
  }

  static getCars() {
    if (!process.browser) return {}
    return JSON.parse(localStorage.getItem('car') || '{}')
  }

  static getCarSelected() {
    return this.getCars().selected
  }

  static getCarSelectedType() {
    const carsCode = ['trucks','cars']
    const carSelected = CarSelectorStore.getCarSelected()
    if (!carSelected) return ''
    const typeCar = parseInt(carSelected.data[0].data.base.type_car_code)
    return carsCode[typeCar]
  }
}
