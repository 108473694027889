export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: false,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: true,
  locales: [{"code":"uk","file":"uk"},{"code":"ru","file":"ru"}],
  defaultLocale: "uk",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "i18n/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"uk","file":"uk"},{"code":"ru","file":"ru"}],
  localeCodes: ["uk","ru"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "u",
  3: "k",
  4: "\"",
  5: ":",
  6: "\"",
  7: ".",
  8: ".",
  9: "/",
  10: "i",
  11: "1",
  12: "8",
  13: "n",
  14: "/",
  15: "u",
  16: "k",
  17: "\"",
  18: ",",
  19: "\"",
  20: "r",
  21: "u",
  22: "\"",
  23: ":",
  24: "\"",
  25: ".",
  26: ".",
  27: "/",
  28: "i",
  29: "1",
  30: "8",
  31: "n",
  32: "/",
  33: "r",
  34: "u",
  35: "\"",
  36: "}",
}

export const localeMessages = {
  'uk': () => import('../../i18n/uk' /* webpackChunkName: "lang-uk" */),
  'ru': () => import('../../i18n/ru' /* webpackChunkName: "lang-ru" */),
}
