import { MutationTree } from 'vuex';
import { IUserStateInterface } from './state';

const mutation: MutationTree<IUserStateInterface> = {
  clear (state: IUserStateInterface) {
    state.id = 0
    state.username = ''
    state.email = ''
  },

  set(state: IUserStateInterface, user: IUserStateInterface) {
    state.id = user.id
    state.username = user.username
    state.email = user.email
  }
};

export default mutation;
