
import { Component, Vue } from 'nuxt-property-decorator'
import PhoneList from './PhoneList.vue'
import LangSelector from './LangSelector.vue'
import Logo from './Logo.vue'
import Cart from '~/modules/cart/components/CartHeader.vue'
import Search from '~/modules/search/components/Search.vue'
import svgTelIco from '~/components/svg/tel-ico.vue'
import svgMail from '~/components/svg/mail.vue'
@Component(
  {components: { PhoneList, LangSelector, Logo, Search, Cart, svgTelIco, svgMail }}
)
export default class extends Vue {

}
