
import { Component, Vue } from 'nuxt-property-decorator'
import svgFlagUa from '~/components/svg/flag-ua.vue'
@Component({components:{svgFlagUa}})
export default class extends Vue {
  lang = this.$i18n.locale
  languages: any[] = [
    { title: 'UA', value: 'uk', icon: '/images/icons/flag_ua.svg' },
    { title: 'RU', value: 'ru', icon: '/images/icons/flag_ua.svg' }
  ]

  get langTitle () {
    const elem = this.languages.find(v => v.value === this.lang)
    return elem ? elem.title : this.languages[0].title
  }

  switchLanguage (code: string) {
    this.lang = code
    this.$i18n.setLocale(code)
    this.$root.$nuxt.$meta().refresh()
  }

  mounted() {
    this.lang = this.$i18n.locale
    if (!this.lang) this.switchLanguage('uk')
  }

}
