
import {Component, Vue} from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  logout() {
    // @ts-ignore
    this.$cookies.set('auth_token', '')
    document.location.replace('/')
  }
}
