export interface IBreadcrumbs {
  to: any;
  name: string;
}

export interface ISeo {
  h1: string;
  title: string;
  description: string;
  keywords: string;
  text: string;
  status: number;
  noindex: boolean;
  breadcrumbs: IBreadcrumbs[];
  canonical?: string | null;
}

export default () => ({
  h1: '',
  title: '',
  description: '',
  keywords: '',
  text: '',
  status: 0,
  noindex: false,
  breadcrumbs: [],
  canonical: null,
});
