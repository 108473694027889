import { Context } from "@nuxt/types";

export default function ({ route, redirect }: Context) {
  const { path, query, hash } = route;
  if (route.path !== '/' && route.path.endsWith('/')) {
    const nextPath = path.replace(/\/+$/, '') || '/';
    const nextRoute = { path: nextPath, query, hash };
    redirect(301, nextRoute);
  }
  if (route.name?.includes('product.') && Object.keys(query).length > 0) {
    const nextRoute = { path, query: {}, hash };
    redirect(301, nextRoute);
  }
}