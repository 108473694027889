import { MutationTree } from 'vuex';
import { ISeo } from './state';

const mutation: MutationTree<ISeo> = {
  clear (state: ISeo) {
    state.h1 = ''
    state.title = ''
    state.description = ''
    state.keywords = ''
    state.text = ''
    state.status = 0
    state.noindex = false
    state.canonical = null
    state.breadcrumbs.splice(0)
  },

  set(state: ISeo, seo: Partial<ISeo>) {
    if ((state.status === 0 || !state.h1) && seo.h1 !== undefined && seo.h1) state.h1 = seo.h1.replace('()', '')
    if ((state.status === 0 || !state.title) && seo.title !== undefined && seo.title) state.title = seo.title.replace('()', '')
    if ((state.status === 0 || !state.description) && seo.description !== undefined && seo.description) state.description = seo.description.replace('()', '')
    if ((state.status === 0 || !state.keywords) && seo.keywords !== undefined && seo.keywords) state.keywords = seo.keywords
    if ((state.status === 0 || !state.text) && seo.text !== undefined) state.text = seo.text
    if (seo.status !== undefined) state.status = seo.status
    if (seo.noindex !== undefined) state.noindex = seo.noindex
    if (seo.canonical !== undefined) state.canonical = seo.canonical
    if (seo.breadcrumbs !== undefined) {
      state.breadcrumbs.splice(0)
      for (const v of seo.breadcrumbs) state.breadcrumbs.push(v)
    }
  },
};

export default mutation;
