import {ActionTree} from 'vuex';
import {StateInterface} from '../index';
import {IUserStateInterface} from './state';

const actions: ActionTree<IUserStateInterface, StateInterface> = {
  async get({commit}) {
    try {
      const response = await this.$axios.get('user/profile')
      commit('set', response.data)
    } catch (e) {
      delete this.$axios.defaults.headers.Authorization
      // @ts-ignore
      this.$cookies.set('auth_token', '')
      console.error(e)
    }
  },
  async initRecaptcha({state}) {
    try {
      // @ts-ignore
      if (this.$isServer) return
      if (!state.is_recaptcha_ready) {
        state.is_recaptcha_ready = true
        // @ts-ignore
        await this.$recaptcha.init()
        console.log('initRecaptcha')
      }
    } catch (e) {
      console.error(e)
    }
  }
}
export default actions;
