
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import FullFixed from './FullFixed.vue'
@Component({
  components: { FullFixed }
})
export default class extends Vue {
  @Prop({ default: '' }) title!: String
  @Prop({ default: 60 }) fontSize!: number
  @Prop({ default: false }) isCenter!: Boolean

  get warningIconStyle() {
    return `width:${this.fontSize}px;height:${this.fontSize}px`
  }

  get warningTitleStyle() {
    return `font-size:${this.fontSize/3}px;font-family: LatoBold`
  }

}
