import {ActionTree} from 'vuex';
import {StateInterface} from '..';
import {ISeo} from './state';

const actions: ActionTree<ISeo, StateInterface> = {
  async get({commit}, {link, query}) {
    try {
      link = link.replace(/^(\/ru)/, '/').replace('//', '/')
      const response = await this.$axios.post('/seo', {link})
      let noindex = false
      if (query?.sort || (query?.page && query?.page > 1)) noindex = true
      commit('clear')

      if (!response.data || Object.keys(response.data).length === 0) {
        commit('set', {noindex})
        return;
      }
      const obj: Partial<ISeo> = {status: parseInt(`${response.data.status}`), noindex, h1: ''}
      obj.title = response.data.title
      obj.description = response.data.description
      obj.text = response.data.text
      obj.breadcrumbs = []
      obj.keywords = ''
      obj.canonical = response.data.canonical
      commit('set', obj)
    } catch (e) {
      commit('clear')
      console.error(e)
    }
  },

  conversion({commit}, order: Record<string, any>) {
    if (!order.id) return
    if (!Array.isArray(order.products)) return
    // @ts-ignore
    this.$ga.require('ecommerce')
    const total = order.price ? order.price : order.products.reduce((p: number, c: any) => p + c.price, 0)
    // @ts-ignore
    this.$ga.ecommerce.addTransaction({
      id: order.id,
      affiliation: '///novaton.ua',
      revenue: total,
      shipping: '',
      tax: '',
    })
    for (const p of order.products) {
      // @ts-ignore
      this.$ga.ecommerce.addItem({
        id: p.code_product_1c,
        name: p.product_name,
        sku: `${p.article}`,
        category: '',
        price: p.price,
        quantity: p.quantity,
      })
    }
    // @ts-ignore
    this.$ga.ecommerce.send()
  },

  set({state}, seo: Partial<ISeo>) {
    if ((state.status === 0 || !state.h1) && seo.h1 !== undefined && seo.h1) state.h1 = seo.h1.replace('()', '')
    if ((state.status === 0 || !state.title) && seo.title !== undefined && seo.title) state.title = seo.title.replace('()', '')
    if ((state.status === 0 || !state.description) && seo.description !== undefined && seo.description) state.description = seo.description.replace('()', '')
    if ((state.status === 0 || !state.keywords) && seo.keywords !== undefined && seo.keywords) state.keywords = seo.keywords
    if ((state.status === 0 || !state.text) && seo.text !== undefined) state.text = seo.text
    if (seo.status !== undefined) state.status = seo.status
    if (seo.noindex !== undefined) state.noindex = seo.noindex
    if (seo.canonical !== undefined) state.canonical = seo.canonical
    if (seo.breadcrumbs !== undefined) {
      state.breadcrumbs.splice(0)
      for (const v of seo.breadcrumbs) state.breadcrumbs.push(v)
    }
  },

  setCatalogMeta({commit, state}, params: Record<string, any>) {
    const obj: Partial<ISeo> = {noindex: false}
    obj.h1 = state.h1
    const filterValues: Record<string, any> = {}
    for (const k in params.currentFilter) {
      if (params.loadFilter[k] === undefined) continue
      const filterValue = params.loadFilter[k].find((v: any) => v.link === params.currentFilter[k])
      if (!filterValue) continue
      filterValues[k] = filterValue;
    }
    if (Object.keys(filterValues).length > 2) obj.noindex = true;

    if (['optics_and_part_body', 'tires'].includes(params.trKey)) obj.noindex = false;

    if (params.currentFilter.sort) obj.noindex = true;
    if (params.currentFilter.page && params.currentFilter.page > 1) {
      obj.title = this.$i18n.t(`seo.catalog.title_paging`, {h1: obj.h1, page: params.currentFilter.page}).toString()
      obj.noindex = true;
    } else {
      obj.title = this.$i18n.t(`seo.catalog.title`, {h1: obj.h1}).toString()
      if (params.description) {
        obj.description = params.description;
      } else {
        obj.description = this.$i18n.te(`seo.catalog.${params.trKey}.description`) ? this.$i18n.t(`seo.catalog.${params.trKey}.description`, {h1: obj.h1}).toString()
          :
          this.$i18n.t('seo.categories.description', {h1: obj.h1}).toString()
      }
    }
    commit('set', obj)
  },

  setCatalogMetaAccumulators({commit}, params: Record<string, any>) {
    const filterValues: Record<string, any> = {}
    for (const k in params.currentFilter) {
      if (params.loadFilter[k] === undefined) continue
      const filterValue = params.loadFilter[k].find((v: any) => v.link === params.currentFilter[k])
      if (!filterValue) continue
      filterValues[k] = filterValue;
    }
    const obj: Partial<ISeo> = {}
    if (Object.values(filterValues).length === 0) return;
    if (filterValues.capacity && filterValues.brand) {
      obj.text = this.$i18n.t(`seo.catalog.accumulators.text_brand_capacity`, {
        brand: filterValues.brand.name,
        capacity: filterValues.capacity.name
      }).toString()
    } else if (filterValues.brand) {
      obj.text = this.$i18n.t(`seo.catalog.accumulators.text_brand`, {brand: filterValues.brand.name}).toString()
    } else {
      obj.text = this.$i18n.t(`seo.catalog.accumulators.text_capacity`, {capacity: filterValues.capacity.name}).toString()
    }
    commit('set', obj)
  },

  catalogMetaOils({commit}, params: Record<string, any>) {
    const filterValues: Record<string, any> = {}
    for (const k in params.currentFilter) {
      if (params.loadFilter[k] === undefined) continue
      const filterValue = params.loadFilter[k].find((v: any) => v.link === params.currentFilter[k])
      if (!filterValue) continue
      filterValues[k] = filterValue;
    }
    const obj: Partial<ISeo> = {}
    if (Object.values(filterValues).length > 2) return;
    if (Object.values(filterValues).length === 1 && filterValues.brand !== undefined) {
      obj.text = this.$i18n.t(`seo.catalog.oils.text_brand`, {brand: filterValues.brand.name}).toString()
    } else if (Object.values(filterValues).length === 2 && filterValues.brand !== undefined && filterValues.viscosity !== undefined) {
      obj.text = this.$i18n.t(`seo.catalog.oils.text_brand_viscosity`, {
        brand: filterValues.brand.name,
        viscosity: filterValues.viscosity.name
      }).toString()
    }
    commit('set', obj)
  },

  catalogMetaCars({commit}, params: Record<string, any>) {
    const filterValues: Record<string, any> = {}
    for (const k in params.currentFilter) {
      if (params.loadFilter[k] === undefined) continue
      const filterValue = params.loadFilter[k].find((v: any) => v.link === params.currentFilter[k])
      if (!filterValue) continue
      filterValues[k] = filterValue;
    }
    if (Object.values(filterValues).length === 0) return;
    const langData = {
      manufacturer: '',
      model: '',
      node: '',
    }
    if (filterValues.manufacturer !== undefined) {
      langData.manufacturer = filterValues.manufacturer.name
    }
    if (filterValues.model !== undefined) {
      langData.model = ` ${filterValues.model.name}`
    }
    if (filterValues.node !== undefined) {
      langData.node = ` ${filterValues.node.name}`
    }
    const obj: Partial<ISeo> = {}
    const isAllData = Object.values(langData).filter((v) => v.length > 0).length === 3
    if (Object.keys(filterValues).length === 3 && isAllData) obj.noindex = false;
    obj.text = this.$i18n.t(`seo.catalog.cars.text`, langData).toString()
    commit('set', obj)
  },

  catalogMetaTrucks({commit}, params: Record<string, any>) {
    const filterValues: Record<string, any> = {}
    for (const k in params.currentFilter) {
      if (params.loadFilter[k] === undefined) continue
      const filterValue = params.loadFilter[k].find((v: any) => v.link === params.currentFilter[k])
      if (!filterValue) continue
      filterValues[k] = filterValue;
    }
    if (Object.values(filterValues).length === 0) return;
    const langData = {
      manufacturer: '',
      model: '',
      node: '',
    }
    if (filterValues.manufacturer !== undefined) {
      langData.manufacturer = filterValues.manufacturer.name
    }
    if (filterValues.model !== undefined) {
      langData.model = ` ${filterValues.model.name}`
    }
    if (filterValues.node !== undefined) {
      langData.node = ` ${filterValues.node.name}`
    }
    const obj: Partial<ISeo> = {}
    const isAllData = Object.values(langData).filter((v) => v.length > 0).length === 3
    if (Object.keys(filterValues).length === 3 && isAllData) obj.noindex = false;
    obj.text = this.$i18n.t(`seo.catalog.trucks.text`, langData).toString()
    commit('set', obj)
  },

  catalogMetaText({commit}, params: Record<string, any>) {
    const filterValues: Record<string, any> = {}
    for (const k in params.currentFilter) {
      if (params.loadFilter[k] === undefined) continue
      const filterValue = params.loadFilter[k].find((v: any) => v.link === params.currentFilter[k])
      if (!filterValue) continue
      filterValues[k] = filterValue;
    }
    const obj: Partial<ISeo> = {}
    if (Object.values(filterValues).length === 0) return;
    const condition = getCondition(params.trKey, filterValues)
    if (condition) {
      const fields: any = {}
      condition.keys.forEach((key: string) => {
        fields[key] = filterValues[key].name
      })
      if (condition.index) {
        obj.noindex = false;
        if (params.currentFilter.sort) obj.noindex = true;
        if (params.currentFilter.page && params.currentFilter.page > 1) {
          obj.noindex = true;
        }
      }
      const name = condition.name || `text_${condition.keys.join('_')}`
      if (this.$i18n.te(`seo.catalog.${params.trKey}.${name}`)) {
        obj.text = this.$i18n.t(`seo.catalog.${params.trKey}.${name}`, fields).toString()
      }
    }
    commit('set', obj)
  }

}

const getCondition = (key: string, filterValues: any) => {
  const conditions: any = {
    tires: [
      {keys: ['brand', 'diameter', 'size', 'season']},
      {keys: ['brand', 'diameter', 'size']},
      {keys: ['brand', 'diameter', 'season']},
      {keys: ['brand', 'diameter']},
      {keys: ['brand', 'size', 'season']},
      {keys: ['brand', 'size']},
      {keys: ['brand']},
      {keys: ['diameter', 'size', 'season'], index: true},
      {keys: ['diameter', 'size']},
      {keys: ['diameter', 'season']},
      {keys: ['diameter']},
      {keys: ['size', 'season']},
      {keys: ['size']},
    ],
    trucks: [
      {keys: ['manufacturer', 'model', 'node'], name: 'text', index: true},
      {keys: ['manufacturer', 'model', 'modifications'], name: 'textManufacturerModelModifications', index: true},
      {keys: ['manufacturer', 'model'], name: 'textManufacturerModel'},
      {keys: ['manufacturer'], name: 'textManufacturer'},
    ],
    cars: [
      {keys: ['manufacturer', 'model', 'node'], name: 'text', index: true},
      {keys: ['manufacturer', 'model', 'modifications'], index: true},
      {keys: ['manufacturer', 'model']},
      {keys: ['manufacturer']},
    ],
    oils: [
      {keys: ['brand', 'viscosity']},
      {keys: ['brand', 'group']},
      {keys: ['brand']}
    ],
    accumulators: [{keys: ['brand', 'capacity']}, {keys: ['brand']}, {keys: ['capacity']}],
  }[key]
  return conditions.find((e: any) => {
    let result = true
    for (let i = 0; i < e.keys.length; i++) {
      result = result && filterValues[e.keys[i]]
      if (!result) break
    }
    return result
  })
}

export default actions
