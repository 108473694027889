import Pagination from "~/std/Pagination";

export interface IPagination {
  page: number,
  total: number,
  limit: number,
  items: any[],
  hasPages: boolean
}

export default () => new Pagination();