export interface ICartStateInterface {
  products: any[]
  isLoadedProduct: boolean
  amount: number
}

export default () => ({
  products: [],
  isLoadedProduct: false,
  amount: 0,
});
