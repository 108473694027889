
import {Component, Vue} from 'nuxt-property-decorator'
import Badge from '~/components/Badge.vue'
import svgCart from '~/components/svg/cart.vue'

@Component({
  components: {Badge, svgCart}
})
export default class extends Vue {

  count() {
    return this.$store.getters['cart/totalProducts']
  }

  getAmount(params: Record<string, any> = {}) {
    this.debounce(() => {
      this._getAmount(params);
    }, 500)()
  }

  async _getAmount(params: Record<string, any> = {}) {
    await this.$store.dispatch('cart/getAmount', params)
  }

  debounce(f: any, ms: number) {
    let isCooldown = false;
    return function() {
      if (isCooldown) return;
      // @ts-ignore
      f.apply(this, arguments);
      isCooldown = true;
      setTimeout(() => {
        isCooldown = false
        }, ms);
    };
  }

  async refresh(v?: any, amountRequestData: Record<string, any> = {}) {
    await this.$store.dispatch('cart/refresh', {product: v, params: amountRequestData})
  }

  async mounted() {
    const self = this
    this.$root.$on('cart', self.refresh)
    this.$once('hook:beforeDestroy', () => this.$root.$off('cart', self.refresh))
    if (!this.$isServer) {
      this.refresh()
    }
  }

  beforeDestroy() {
    /** @ts-ignore */
    // if (!this.$recaptcha) return;
    /** @ts-ignore */
    // this.$recaptcha.destroy()
  }
}
