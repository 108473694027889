import { ActionTree } from 'vuex';
import { StateInterface } from '../index';
import { IPagination } from './state';
import Pagination from '~/std/Pagination';

const actions: ActionTree<IPagination, StateInterface> = {
  async load({ commit, state }, { link, params, castItem, append }) {
    try {
      const result = await this.$axios.get(link, { params })
      const items = []
      const paging: Partial<IPagination> = {
        total: result.data.total,
      }
      paging.hasPages = false;
      for (const key in result.data.list) {
        const item = result.data.list[key]
        if (Number.parseInt(key) >= state.limit) {
          paging.hasPages = true;
          continue;
        }
        items.push(castItem ? castItem(item) : item)
      }
      paging.items = items
      if (paging.total && paging.total < 0) {
        paging.hasPages = items.length >= state.limit
      } else if (paging.total && paging.total >= 0) {
        const _total = Math.ceil(paging.total / state.limit)
        paging.hasPages = _total > state.page
      }
      commit('set', { data: paging, append })
    } catch (e: any) {
      commit('set', { data: new Pagination() })
      throw e
    }
  },

  clear({ commit }) {
    commit('set', { data: new Pagination() })
  }
}
export default actions;
