import Vue from 'vue'
// @ts-ignore
import { Button, Input, Dropdown, Icon, Modal, Tabs, Breadcrumb, Loading, Toast, Select, Field, Dialog, Autocomplete } from 'buefy'
// @ts-ignore
import VueMask from 'v-mask'

Vue.use(Modal);
Vue.use(Tabs);
Vue.use(Dropdown);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Input);
Vue.use(Breadcrumb);
// Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(Select);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(VueMask);
