import { Context } from '@nuxt/types'

export default function ({ i18n, $config, $axios, route }: Context) {
  i18n.defaultLocale = $config.defaultLocale
  // onLanguageSwitched called right after a new locale has been set
  // @ts-ignore
  i18n.onLanguageSwitched = (_oldLocale: any, newLocale: any) => {
    $axios.setHeader('Language', newLocale)
  }
  
  if (route.path.match(/^\/ru$/) || route.path.match(/^\/ru\/.*/)) {
    i18n.setLocale('ru')
  } else {
    i18n.setLocale('uk')
  }
}
