export interface IUserStateInterface {
  id: number
  username: string
  email: string
  phone: string
  filial_code: string
  delivery_code: string
  freighter_code: string
  freighter_warehouse_code: string
  payment_code: string
  is_recaptcha_ready: boolean
}

export default () => ({
  id: 0,
  username: '',
  email: '',
  phone: '',
  filial_code: '',
  delivery_code: '',
  freighter_code: '',
  freighter_warehouse_code: '',
  payment_code: '',
  is_recaptcha_ready: false,
});
