import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3c05d08d = () => interopDefault(import('../_pages/cart.vue' /* webpackChunkName: "" */))
const _b0677658 = () => interopDefault(import('../_pages/categories/list.vue' /* webpackChunkName: "" */))
const _4e46686b = () => interopDefault(import('../_pages/error.vue' /* webpackChunkName: "" */))
const _6f0e5364 = () => interopDefault(import('../_pages/blog/list.vue' /* webpackChunkName: "" */))
const _56ea0f9a = () => interopDefault(import('../pages/auth/profile.vue' /* webpackChunkName: "" */))
const _22316502 = () => interopDefault(import('../_pages/reviews/list.vue' /* webpackChunkName: "" */))
const _5731c8aa = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2c0cb79a = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _4b89f9fc = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _882a9d34 = () => interopDefault(import('../_pages/cartRegistration.vue' /* webpackChunkName: "" */))
const _0201e8d8 = () => interopDefault(import('../_pages/about' /* webpackChunkName: "" */))
const _19e09328 = () => interopDefault(import('../_pages/contacts' /* webpackChunkName: "" */))
const _6cc84ec9 = () => interopDefault(import('../_pages/delivery' /* webpackChunkName: "" */))
const _bd065ede = () => interopDefault(import('../_pages/payment' /* webpackChunkName: "" */))
const _1d705456 = () => interopDefault(import('../_pages/search.vue' /* webpackChunkName: "" */))
const _75edc660 = () => interopDefault(import('../_pages/catalog/accumulators.vue' /* webpackChunkName: "" */))
const _5f020204 = () => interopDefault(import('../_pages/catalog/oils.vue' /* webpackChunkName: "" */))
const _5888c1b0 = () => interopDefault(import('../_pages/catalog/optics_and_part_body.vue' /* webpackChunkName: "" */))
const _2699dcd8 = () => interopDefault(import('../_pages/catalog/tires_truck.vue' /* webpackChunkName: "" */))
const _e07cd510 = () => interopDefault(import('../_pages/catalog/tires.vue' /* webpackChunkName: "" */))
const _3901bd7c = () => interopDefault(import('../_pages/catalog/cars.vue' /* webpackChunkName: "" */))
const _62b4ecd7 = () => interopDefault(import('../_pages/catalog/trucks.vue' /* webpackChunkName: "" */))
const _5716946f = () => interopDefault(import('../_pages/categories/main.vue' /* webpackChunkName: "" */))
const _790b0681 = () => interopDefault(import('../_pages/blog/detail.vue' /* webpackChunkName: "" */))
const _631b649d = () => interopDefault(import('../_pages/product/card.vue' /* webpackChunkName: "" */))
const _34ad1e5f = () => interopDefault(import('../_pages/reviews/answer.vue' /* webpackChunkName: "" */))
const _2e1ec4e2 = () => interopDefault(import('../_pages/order/confirm.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _3c05d08d,
    caseSensitive: true,
    name: "cart___uk"
  }, {
    path: "/categories",
    component: _b0677658,
    caseSensitive: true,
    name: "categories.list___uk"
  }, {
    path: "/error",
    component: _4e46686b,
    name: "error___uk"
  }, {
    path: "/news",
    component: _6f0e5364,
    caseSensitive: true,
    name: "blog___uk"
  }, {
    path: "/profile",
    component: _56ea0f9a,
    caseSensitive: true,
    name: "profile___uk"
  }, {
    path: "/reviews",
    component: _22316502,
    caseSensitive: true,
    name: "reviews___uk"
  }, {
    path: "/ru",
    component: _5731c8aa,
    pathToRegexpOptions: {"strict":true},
    name: "index___ru"
  }, {
    path: "/auth/login",
    component: _2c0cb79a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___uk"
  }, {
    path: "/auth/profile",
    component: _56ea0f9a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-profile___uk"
  }, {
    path: "/auth/register",
    component: _4b89f9fc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___uk"
  }, {
    path: "/cart/registration",
    component: _882a9d34,
    caseSensitive: true,
    name: "cart.registration___uk"
  }, {
    path: "/page/about",
    component: _0201e8d8,
    caseSensitive: true,
    name: "about___uk"
  }, {
    path: "/page/contacts",
    component: _19e09328,
    caseSensitive: true,
    name: "contacts___uk"
  }, {
    path: "/page/delivery",
    component: _6cc84ec9,
    caseSensitive: true,
    name: "delivery___uk"
  }, {
    path: "/page/payments",
    component: _bd065ede,
    caseSensitive: true,
    name: "payment___uk"
  }, {
    path: "/ru/cart",
    component: _3c05d08d,
    caseSensitive: true,
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _b0677658,
    caseSensitive: true,
    name: "categories.list___ru"
  }, {
    path: "/ru/error",
    component: _4e46686b,
    name: "error___ru"
  }, {
    path: "/ru/news",
    component: _6f0e5364,
    caseSensitive: true,
    name: "blog___ru"
  }, {
    path: "/ru/profile",
    component: _56ea0f9a,
    caseSensitive: true,
    name: "profile___ru"
  }, {
    path: "/ru/reviews",
    component: _22316502,
    caseSensitive: true,
    name: "reviews___ru"
  }, {
    path: "/search/name",
    component: _1d705456,
    caseSensitive: true,
    name: "search.name___uk"
  }, {
    path: "/ru/auth/login",
    component: _2c0cb79a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___ru"
  }, {
    path: "/ru/auth/profile",
    component: _56ea0f9a,
    pathToRegexpOptions: {"strict":true},
    name: "auth-profile___ru"
  }, {
    path: "/ru/auth/register",
    component: _4b89f9fc,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___ru"
  }, {
    path: "/ru/cart/registration",
    component: _882a9d34,
    caseSensitive: true,
    name: "cart.registration___ru"
  }, {
    path: "/ru/page/about",
    component: _0201e8d8,
    caseSensitive: true,
    name: "about___ru"
  }, {
    path: "/ru/page/contacts",
    component: _19e09328,
    caseSensitive: true,
    name: "contacts___ru"
  }, {
    path: "/ru/page/delivery",
    component: _6cc84ec9,
    caseSensitive: true,
    name: "delivery___ru"
  }, {
    path: "/ru/page/payments",
    component: _bd065ede,
    caseSensitive: true,
    name: "payment___ru"
  }, {
    path: "/ru/search/name",
    component: _1d705456,
    caseSensitive: true,
    name: "search.name___ru"
  }, {
    path: "/",
    component: _5731c8aa,
    pathToRegexpOptions: {"strict":true},
    name: "index___uk"
  }, {
    path: "/ru/catalog/batteries/:capacity(capacity-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _75edc660,
    caseSensitive: true,
    name: "catalog.accumulators_not_brand___ru"
  }, {
    path: "/ru/catalog/batteries/:brand?/:capacity(capacity-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _75edc660,
    caseSensitive: true,
    name: "catalog.accumulators___ru"
  }, {
    path: "/ru/catalog/oils/:viscosity(viscosity-[a-z_0-9\\-]+)?/:group(group-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _5f020204,
    caseSensitive: true,
    name: "catalog.oils_not_brand___ru"
  }, {
    path: "/ru/catalog/optics_and_part_body/:model(model-[a-z_0-9\\-]+)?/:line(line-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _5888c1b0,
    caseSensitive: true,
    name: "catalog.optics_and_part_body_not_brand___ru"
  }, {
    path: "/ru/catalog/oils/:brand?/:viscosity(viscosity-[a-z_0-9\\-]+)?/:group(group-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _5f020204,
    caseSensitive: true,
    name: "catalog.oils___ru"
  }, {
    path: "/ru/catalog/optics_and_part_body/:manufacturer?/:model(model-[a-z_0-9\\-]+)?/:line(line-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _5888c1b0,
    caseSensitive: true,
    name: "catalog.optics_and_part_body___ru"
  }, {
    path: "/ru/catalog/shiny-dlja-gruzovyh-avtomobilej/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:width(width-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _2699dcd8,
    caseSensitive: true,
    name: "catalog.tires-truck_not_brand___ru"
  }, {
    path: "/ru/catalog/shiny-dlja-legkovyh-avtomobilej/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:season(season-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _e07cd510,
    caseSensitive: true,
    name: "catalog.tires-legkovyh_not_brand___ru"
  }, {
    path: "/ru/catalog/tires/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:season(season-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _e07cd510,
    caseSensitive: true,
    name: "catalog.tires_not_brand___ru"
  }, {
    path: "/ru/catalog/shiny-dlja-gruzovyh-avtomobilej/:brand?/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:width(width-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _2699dcd8,
    caseSensitive: true,
    name: "catalog.tires-truck___ru"
  }, {
    path: "/ru/catalog/shiny-dlja-legkovyh-avtomobilej/:brand?/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:season(season-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _e07cd510,
    caseSensitive: true,
    name: "catalog.tires-legkovyh___ru"
  }, {
    path: "/ru/catalog/tires/:brand?/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:season(season-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _e07cd510,
    caseSensitive: true,
    name: "catalog.tires___ru"
  }, {
    path: "/ru/catalog/cars/:model(model-[a-z_0-9\\-]+)?/:fuel(fuel-[a-z_0-9\\-]+)?/:modifications(modifications-[a-z_0-9\\-]+)?/:hp(hp-[a-z_0-9\\-]+)?/:node(node-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _3901bd7c,
    caseSensitive: true,
    name: "catalog.cars_not_brand___ru"
  }, {
    path: "/ru/catalog/trucks/:model(model-[a-z_0-9\\-]+)?/:fuel(fuel-[a-z_0-9\\-]+)?/:modifications(modifications-[a-z_0-9\\-]+)?/:hp(hp-[a-z_0-9\\-]+)?/:node(node-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _62b4ecd7,
    caseSensitive: true,
    name: "catalog.trucks_not_brand___ru"
  }, {
    path: "/ru/catalog/cars/:manufacturer?/:model(model-[a-z_0-9\\-]+)?/:fuel(fuel-[a-z_0-9\\-]+)?/:modifications(modifications-[a-z_0-9\\-]+)?/:hp(hp-[a-z_0-9\\-]+)?/:node(node-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _3901bd7c,
    caseSensitive: true,
    name: "catalog.cars___ru"
  }, {
    path: "/ru/catalog/trucks/:manufacturer?/:model(model-[a-z_0-9\\-]+)?/:fuel(fuel-[a-z_0-9\\-]+)?/:modifications(modifications-[a-z_0-9\\-]+)?/:hp(hp-[a-z_0-9\\-]+)?/:node(node-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _62b4ecd7,
    caseSensitive: true,
    name: "catalog.trucks___ru"
  }, {
    path: "/ru/categories/:cat",
    component: _5716946f,
    caseSensitive: true,
    name: "categories.main___ru"
  }, {
    path: "/ru/news/:id",
    component: _790b0681,
    caseSensitive: true,
    name: "blog.detail___ru"
  }, {
    path: "/ru/product/:link",
    component: _631b649d,
    caseSensitive: true,
    name: "product.view___ru"
  }, {
    path: "/ru/reviews/:id",
    component: _34ad1e5f,
    caseSensitive: true,
    name: "reviews.comment___ru"
  }, {
    path: "/ru/order/:id/confirm",
    component: _2e1ec4e2,
    caseSensitive: true,
    name: "order.confirm___ru"
  }, {
    path: "/catalog/batteries/:capacity(capacity-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _75edc660,
    caseSensitive: true,
    name: "catalog.accumulators_not_brand___uk"
  }, {
    path: "/catalog/batteries/:brand?/:capacity(capacity-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _75edc660,
    caseSensitive: true,
    name: "catalog.accumulators___uk"
  }, {
    path: "/catalog/oils/:viscosity(viscosity-[a-z_0-9\\-]+)?/:group(group-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _5f020204,
    caseSensitive: true,
    name: "catalog.oils_not_brand___uk"
  }, {
    path: "/catalog/optics_and_part_body/:model(model-[a-z_0-9\\-]+)?/:line(line-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _5888c1b0,
    caseSensitive: true,
    name: "catalog.optics_and_part_body_not_brand___uk"
  }, {
    path: "/catalog/oils/:brand?/:viscosity(viscosity-[a-z_0-9\\-]+)?/:group(group-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _5f020204,
    caseSensitive: true,
    name: "catalog.oils___uk"
  }, {
    path: "/catalog/optics_and_part_body/:manufacturer?/:model(model-[a-z_0-9\\-]+)?/:line(line-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _5888c1b0,
    caseSensitive: true,
    name: "catalog.optics_and_part_body___uk"
  }, {
    path: "/catalog/shiny-dlja-gruzovyh-avtomobilej/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:width(width-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _2699dcd8,
    caseSensitive: true,
    name: "catalog.tires-truck_not_brand___uk"
  }, {
    path: "/catalog/shiny-dlja-legkovyh-avtomobilej/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:season(season-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _e07cd510,
    caseSensitive: true,
    name: "catalog.tires-legkovyh_not_brand___uk"
  }, {
    path: "/catalog/tires/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:season(season-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _e07cd510,
    caseSensitive: true,
    name: "catalog.tires_not_brand___uk"
  }, {
    path: "/catalog/shiny-dlja-gruzovyh-avtomobilej/:brand?/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:width(width-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _2699dcd8,
    caseSensitive: true,
    name: "catalog.tires-truck___uk"
  }, {
    path: "/catalog/shiny-dlja-legkovyh-avtomobilej/:brand?/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:season(season-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _e07cd510,
    caseSensitive: true,
    name: "catalog.tires-legkovyh___uk"
  }, {
    path: "/catalog/tires/:brand?/:diameter(diameter-[A-Za-z_0-9\\-.]+)?/:size(size-[a-z_0-9\\-]+)?/:season(season-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _e07cd510,
    caseSensitive: true,
    name: "catalog.tires___uk"
  }, {
    path: "/catalog/cars/:model(model-[a-z_0-9\\-]+)?/:fuel(fuel-[a-z_0-9\\-]+)?/:modifications(modifications-[a-z_0-9\\-]+)?/:hp(hp-[a-z_0-9\\-]+)?/:node(node-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _3901bd7c,
    caseSensitive: true,
    name: "catalog.cars_not_brand___uk"
  }, {
    path: "/catalog/trucks/:model(model-[a-z_0-9\\-]+)?/:fuel(fuel-[a-z_0-9\\-]+)?/:modifications(modifications-[a-z_0-9\\-]+)?/:hp(hp-[a-z_0-9\\-]+)?/:node(node-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _62b4ecd7,
    caseSensitive: true,
    name: "catalog.trucks_not_brand___uk"
  }, {
    path: "/catalog/cars/:manufacturer?/:model(model-[a-z_0-9\\-]+)?/:fuel(fuel-[a-z_0-9\\-]+)?/:modifications(modifications-[a-z_0-9\\-]+)?/:hp(hp-[a-z_0-9\\-]+)?/:node(node-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _3901bd7c,
    caseSensitive: true,
    name: "catalog.cars___uk"
  }, {
    path: "/catalog/trucks/:manufacturer?/:model(model-[a-z_0-9\\-]+)?/:fuel(fuel-[a-z_0-9\\-]+)?/:modifications(modifications-[a-z_0-9\\-]+)?/:hp(hp-[a-z_0-9\\-]+)?/:node(node-[a-z_0-9\\-]+)?/:page(page-[0-9]+)?",
    component: _62b4ecd7,
    caseSensitive: true,
    name: "catalog.trucks___uk"
  }, {
    path: "/categories/:cat",
    component: _5716946f,
    caseSensitive: true,
    name: "categories.main___uk"
  }, {
    path: "/news/:id",
    component: _790b0681,
    caseSensitive: true,
    name: "blog.detail___uk"
  }, {
    path: "/product/:link",
    component: _631b649d,
    caseSensitive: true,
    name: "product.view___uk"
  }, {
    path: "/reviews/:id",
    component: _34ad1e5f,
    caseSensitive: true,
    name: "reviews.comment___uk"
  }, {
    path: "/order/:id/confirm",
    component: _2e1ec4e2,
    caseSensitive: true,
    name: "order.confirm___uk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
