
import { Component, Vue } from 'nuxt-property-decorator'
import PhoneList from './PhoneList.vue'
import LangSelector from './LangSelector.vue'
import Logo from './Logo.vue'
import Cart from '~/modules/cart/components/CartHeader.vue'
import Search from '~/modules/search/components/Search.vue'
import UserMenu from "~/components/main/UserMenu.vue";
@Component(
  {components: {UserMenu, PhoneList, LangSelector, Logo, Search, Cart }}
)
export default class extends Vue {
  showModal = false
}
