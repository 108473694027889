import { GetterTree } from 'vuex';
import { StateInterface } from '../index';
import { IUserStateInterface } from './state';

const getters: GetterTree<IUserStateInterface, StateInterface> = {
  someAction (/* context */) {
    // your code
  }
};

export default getters;
