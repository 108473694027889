
import { Component, Vue } from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  model = {
    search: ''
  }

  search() {
    if (this.model.search.length < 3) {
      return this.$buefy.toast.open({ message: this.$t('component.search.minstr').toString() })
    }
    const el:any = document.querySelector('.is-mobile-modal')
    if (el) el.click()
    this.$router.push(this.localePath({ name: 'search.name', query: { product_name: this.model.search } }));
  }

  created() {
    this.model.search = (this.$route.query.product_name ? this.$route.query.product_name : '') as string
  }
}
