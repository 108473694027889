
import {Component, Prop, Vue} from 'nuxt-property-decorator'
import Warning from '~/components/Warning.vue'

@Component({
  components: {Warning},
  name: 'ErrorPage'
})
export default class extends Vue {
  @Prop({default: 0}) error!: number
}
