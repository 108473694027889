import { IPagination } from "@/store/pagination/state"

export default class Pagination implements IPagination {
  page = 1
  total = 100
  limit = 20
  items = []
  hasPages = false

  totalPages() {
    return Math.ceil(this.total / this.limit)
  }

  // hasPage() {
  //   return this.totalPages() > this.page
  // }

  getCatalogPageParam(page: number) {
    return `page-${page}`
  }

  toJSON () {
    return { ...this } // here I make a POJO's copy of the class instance
  }
}
