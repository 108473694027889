import { MutationTree } from 'vuex';
import { IPagination } from './state';

const mutation: MutationTree<IPagination> = {
  set(state: IPagination, { data, append }: { data: Partial<IPagination>, append: boolean }) {
    if (data.total !== undefined) state.total = data.total
    if (data.page) state.page = data.page
    if (data.limit) state.limit = data.limit
    if (data.hasPages !== undefined) state.hasPages = data.hasPages
    if (data.items) {
      if (!append) state.items.splice(0)
      data.items.map((v: any) => state.items.push(v))
    }
  }
};

export default mutation;
