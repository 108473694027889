
import {Component, Vue} from 'nuxt-property-decorator'
import Header from '~/components/main/Header.vue'
import Footer from '~/components/main/Footer.vue'
import TopMenu from '~/components/main/TopMenu.vue'
import FullFixed from '~/components/FullFixed.vue'

@Component({
  components: {Header, Footer, TopMenu, FullFixed}
})
export default class extends Vue {
  isFetching = false

  head() {
    const meta: any = [
      {
        hid: 'description',
        name: 'description',
        content: this.$store.state.seo.description
      }
    ];
    const url = this.$config.projectUrl ? this.$config.projectUrl : '';
    const links: any[] = [
      {rel: 'alternate', href: `${url}${this.getBaseRoute()}`, hreflang: 'uk'},
      {rel: 'alternate', href: `${url}/ru${this.getBaseRoute()}`, hreflang: 'ru'},
      {rel: 'alternate', href: `${url}${this.getBaseRoute()}`, hreflang: 'x-default'},
    ];
    if (this.$store.state.seo.noindex) {
      meta.push({
        hid: 'robots',
        name: 'robots',
        content: 'noindex, follow'
      })
    } else {
      meta.push({
        hid: 'robots',
        name: 'robots',
        content: 'index, follow'
      })
      const queryKeys = Object.keys(this.$route.query).filter((k) => !['page', 'sort', 's'].includes(k));
      if (this.$store.state.seo.canonical) {
        links.push({ rel: 'canonical', href: this.$store.state.seo.canonical })
      } else if (queryKeys.length > 0) {
        const canonicalLink = `${url}${this.$route.path === '/' ? '' : this.$route.path}`;
        links.push({ rel: 'canonical', href: canonicalLink })
      }
    }
    meta.push({property: 'og:title', content: this.$store.state.seo.title})
    meta.push({property: 'og:description', content: this.$store.state.seo.description})
    const img = this.$route.path.includes('product')
      ? this.$store.state.product?.images[0]
      : `${url}/images/logoMobile.png`
    meta.push({property: 'og:image', content: img})
    meta.push({property: 'og:url', content: `${url}${this.$route.path}`})
    meta.push({property: 'twitter:card', content: 'summary_large_image'})
    return {
      title: this.$store.state.seo.title,
      htmlAttrs: {lang: this.$i18n.locale},
      meta,
      script: [
        {
          type: 'application/ld+json',
          innerHTML: `{"@context": "https://schema.org","@type": "Organization","url": "https://novaton.ua","logo": "https://novaton.ua/images/logoMobile.png"}`
        }
      ], // {src: ''}
      __dangerouslyDisableSanitizers: ['script', 'innerHTML'],
      link: links, // {rel: 'stylesheet', href: ''}
    }
  }

  getSeoText() {
    return this.$isServer ? "[[?SEO_TEXT?]]" : this.$store.state.seo.text
  }

  getBaseRoute() {
    let path = this.$route.path.replace('/ru/', '/')
    path = path.replace(/^\/ru$/, '/')
    return !path || path === '/' ? '' : path
  }

  created() {
    this.$router.beforeEach(async (to: any, from: any, next: Function) => {
      if (to.path.includes('cart/reg')) {
        if (!this.$store.state.cart.products.length) return location.replace(this.localePath({name: 'cart'}))
      }
      if (!to.path.includes('categories') || !to.path.includes('optics_and_part_body')) this.$root.$emit('resetSelectCategory')
      await this.getSeo(to.path, to.query);
      next()
    })
    // this.init()
  }

  async fetch() {
    this.isFetching = true;
    await this.init();
  }

  async init() {
    // @ts-ignore
    const token = this.$cookies.get('auth_token')
    if (token) {
      this.$axios.defaults.headers.Authorization = `Bearer ${token}`;
      await this.getUser()
    }
    await this.getSeo(this.$route.path, this.$route.query)
  }

  async getUser() {
    await this.$store.dispatch('user/get')
    if (this.$store.state.user.id === 0) {
      // @ts-ignore
      this.$cookies.set('auth_token', '')
    }
  }

  async getSeo(link: string, query: any) {
    await this.$store.dispatch('seo/get', {link, query})
  }

  mounted() {
    if (!this.$isServer) this.$store.dispatch('cart/refresh')
  }
}
